import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../store/user/userActions';
import { clearMessage } from '../../store/user/userSlice';
import { Spinner } from '../spinner';
import Styles from './forgotPasswordScreen.module.scss';

function ForgotPasswordScreen() {
    const { loading, error, message } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const emailRef = useRef(null);
    const [isRedirecting, setIsRedirecting] = useState(false);

    // Clear messages when component unmounts
    useEffect(() => {
        return () => {
            dispatch(clearMessage());
        };
    }, [dispatch]);

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const email = emailRef.current?.value;

        try {
            const result = await dispatch(forgotPassword({ email })).unwrap();

            if (result) {
                setIsRedirecting(true);
                // 3-second timeout before redirect for these reasons:
                // 1. Allows user to read the success message
                // 2. Provides visual feedback that email was sent
                // 3. Ensures OTP system has time to process
                // 4. Better UX than immediate redirect
                setTimeout(() => {
                    navigate('/reset-password');
                }, 3000);
            }
        } catch (err) {
            console.error('Failed to send reset code:', err);
        }
    };

    return (
        <section className={Styles.mainContainer}>
            <div className={Styles.innerContainer}>
                <h2 className="text-center font-weight-normal pb-4">Forgot Password</h2>
                <form onSubmit={handleForgotPassword}>
                    <div className={Styles.inputsContainer}>
                        <div className={Styles.loginText}>Enter your email</div>
                        <div className={Styles.inputContainer}>
                            <label htmlFor="email">Email:</label>
                            <input
                                name="email"
                                id="email"
                                ref={emailRef}
                                type="email"
                                disabled={loading || isRedirecting}
                                required
                            />
                        </div>
                    </div>
                    {loading && <Spinner />}
                    {error && (
                        <p className={`${Styles.message} ${Styles.error}`}>
                            {error}
                        </p>
                    )}
                    {message && (
                        <p className={`${Styles.message} ${Styles.success}`}>
                            {message}
                            {isRedirecting && " Redirecting to reset password page..."}
                        </p>
                    )}
                    <button 
                        type="submit" 
                        className={`${Styles.formButton} ${Styles.primary}`}
                        disabled={loading || isRedirecting}
                    >
                        {loading ? 'Sending...' : isRedirecting ? 'Code Sent!' : 'Send Reset Code'}
                    </button>
                    <button 
                        type="button" 
                        onClick={() => navigate('/sign-in')} 
                        className={Styles.formButton}
                        disabled={loading || isRedirecting}
                    >
                        Back to Login
                    </button>
                </form>
            </div>
        </section>
    );
}

export { ForgotPasswordScreen };