import { createSlice } from "@reduxjs/toolkit";
import { registerUser, loginUser, logout, getUserDetails, resetPassword, forgotPassword } from "./userActions";

const authToken = localStorage.getItem("userToken")
  ? JSON.parse(localStorage.getItem("userToken"))
  : null;
const userInfo = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
const initialState = {
  loading: false,
  userInfo,
  authToken,
  error: null,
  success: "",
  message: null, // Add this for forgot/reset password messages
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.message = null;
      state.error = null;
    },
  },
  extraReducers: {
    // register user
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload; // registration successful
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Login User
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.UserData;
      // state.authToken = payload.access_token;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Logout User
    [logout.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [logout.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
      state.authToken = null;
      state.error = null;
      state.userInfo = null;
      localStorage.setItem("userToken", null);
      localStorage.setItem("userInfo", null);
    },
    [logout.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [getUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.user;
    },
    [getUserDetails.rejected]: (state) => {
      state.loading = false;
    },
    [forgotPassword.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.message = payload.message;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Reset Password
    [resetPassword.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.message = payload.message;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export default userSlice.reducer;
export const { clearMessage } = userSlice.actions;
