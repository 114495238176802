import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../store/user/userActions';
import { clearMessage } from '../../store/user/userSlice';
import { Spinner } from '../spinner';
import Styles from './resetPasswordScreen.module.scss';

function ResetPasswordScreen() {
    const { loading, error, message } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [localError, setLocalError] = useState('');
    const [isRedirecting, setIsRedirecting] = useState(false);
    
    const emailRef = useRef(null);
    const otpRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    useEffect(() => {
        return () => {
            dispatch(clearMessage());
        };
    }, [dispatch]);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLocalError('');

        const email = emailRef.current?.value;
        const otp = otpRef.current?.value;
        const newPassword = newPasswordRef.current?.value;
        const confirmPassword = confirmPasswordRef.current?.value;

        // Validations
        if (!email || !otp || !newPassword || !confirmPassword) {
            setLocalError('Please fill all fields');
            return;
        }

        if (!/^\d{6}$/.test(otp)) {
            setLocalError('OTP must be 6 digits');
            return;
        }

        if (newPassword !== confirmPassword) {
            setLocalError('Passwords do not match');
            return;
        }

        if (newPassword.length < 6) {
            setLocalError('Password must be at least 6 characters long');
            return;
        }

        try {
            const result = await dispatch(resetPassword({
                email,
                otp,
                newPassword
            })).unwrap();

            if (result) {
                setIsRedirecting(true);
                // 3-second delay to show success message before redirect
                setTimeout(() => {
                    navigate('/sign-in');
                }, 3000);
            }
        } catch (err) {
            console.error('Password reset failed:', err);
        }
    };

    return (
        <section className={Styles.mainContainer}>
            <div className={Styles.innerContainer}>
                <h2 className="text-center font-weight-normal pb-4">Reset Password</h2>
                <form onSubmit={handleResetPassword}>
                    <div className={Styles.inputsContainer}>
                        <div className={Styles.loginText}>Enter Reset Details</div>
                        <div className={Styles.inputContainer}>
                            <label htmlFor="email">Email:</label>
                            <input
                                name="email"
                                id="email"
                                ref={emailRef}
                                type="email"
                                disabled={loading || isRedirecting}
                                required
                            />
                        </div>
                        <div className={Styles.inputContainer}>
                            <label htmlFor="otp">OTP Code:</label>
                            <input
                                name="otp"
                                id="otp"
                                ref={otpRef}
                                type="text"
                                className={Styles.otpInput}
                                maxLength={6}
                                pattern="\d{6}"
                                title="Please enter 6-digit OTP code"
                                disabled={loading || isRedirecting}
                                required
                            />
                        </div>
                        <div className={Styles.inputContainer}>
                            <label htmlFor="newPassword">New Password:</label>
                            <input
                                name="newPassword"
                                id="newPassword"
                                ref={newPasswordRef}
                                type="password"
                                minLength={6}
                                disabled={loading || isRedirecting}
                                required
                            />
                        </div>
                        <div className={Styles.inputContainer}>
                            <label htmlFor="confirmPassword">Confirm Password:</label>
                            <input
                                name="confirmPassword"
                                id="confirmPassword"
                                ref={confirmPasswordRef}
                                type="password"
                                minLength={6}
                                disabled={loading || isRedirecting}
                                required
                            />
                        </div>
                        
                        <div className={Styles.passwordRequirements}>
                            Password must be at least 6 characters long
                        </div>
                    </div>

                    {loading && <Spinner />}
                    {(error || localError) && (
                        <p className={`${Styles.message} ${Styles.error}`}>
                            {error || localError}
                        </p>
                    )}
                    {message && (
                        <p className={`${Styles.message} ${Styles.success}`}>
                            {message}
                            {isRedirecting && " Redirecting to login page..."}
                        </p>
                    )}

                    <button 
                        type="submit" 
                        className={`${Styles.formButton} ${Styles.primary}`}
                        disabled={loading || isRedirecting}
                    >
                        {loading ? 'Processing...' : isRedirecting ? 'Password Reset!' : 'Reset Password'}
                    </button>
                    <button 
                        type="button" 
                        onClick={() => navigate('/sign-in')} 
                        className={Styles.formButton}
                        disabled={loading || isRedirecting}
                    >
                        Back to Login
                    </button>
                </form>
            </div>
        </section>
    );
}

export { ResetPasswordScreen };